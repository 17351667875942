import moment from 'moment'
import logger from './logger'

const login = async (username, password, setUser, setLogging, setLogged, notLogged, setEmpresa) => {
	setLogging(true)
	let domain = username.includes('tvfuego') ? 'tvf' : 'uv'
	try {
		const response = await fetch(`${process.env.REACT_APP_BRIDGE_API}/${domain}/prod/ldap/login`,
			{
				method: 'POST',
				body: JSON.stringify({ username: username, password: password }),
				headers: {
					'Authorization': process.env.REACT_APP_BRIDGE_API_KEY,
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				}
			}
		)
		const responseJSON = await response.json()
		console.log('responseJSON', responseJSON)

		if (responseJSON != 'LDAP error') {
			logger(`Inicio sesion ${responseJSON[0].userprincipalname[0]} `)

			let grupos = responseJSON[0].memberof
				? Object
					.values(responseJSON[0].memberof)
					.filter(group => typeof group !== 'number')
					.map(group => group.split(',')[0].replace('CN=', ''))
				: []
			if (grupos.length >= 1) {
				if (JSON.stringify(grupos).includes('CUO')) {
					localStorage.setItem('empresa', domain)
					localStorage.setItem('logged', true)
					localStorage.setItem('user', JSON.stringify(responseJSON[0]))
					localStorage.setItem('fend', moment().add(1, 'day'))
					setEmpresa(domain)
					setUser(responseJSON[0])
					setLogging(false)
					setLogged(true)

				} else {
					notLogged('Hubo un error, no tiene grupo en CUO asignado')
				}
			} else {
				notLogged('Hubo un error, no tiene grupo asignado')
			}
		} else {
			notLogged('Hubo un error con sus credenciales')
		}
	} catch (e) {
		notLogged('Usuario Invalido', e)
	}
}

export default login