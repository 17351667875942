import Axios from 'axios'
import ConfigContext from '../../contexts/ConfigContext'
import {useContext} from 'react'
import {useHistory} from 'react-router-dom'

const useDelete = (name,path) => {
    const context = useContext(ConfigContext)
    const history = useHistory()

    const deleteItem = (getList,titulo,id) => {

        if (window.confirm(`¿Desea eliminar: [${titulo}] de ${name}`)){
            Axios.delete(`${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/${name}/${id}`,{data:{id:id, titulo:titulo, mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0]}, headers: {Authorization: process.env.REACT_APP_CUO_API_KEY}})
                .then(() => {
                    history.push(`${path}`)
                    getList()
                })
        }
    }

    return [deleteItem]
}

export default useDelete
