import GoogleMapReact from 'google-map-react'
import React, { useContext, useEffect, useState } from 'react'
import FloatingWindow from '../../components/Global/FloatingWindow'
import CONSTS from '../../consts/consts'
import MAPSTYLES from '../../styles/mapstyles'
import useFetch from '../../hooks/useFetch'
import ConfigContext from '../../contexts/ConfigContext'
import LayerSelector from '../../components/Global/LayerSelector'
const FACTURAS_COLORES = {
    0: 'ffffff',
    1: 'b2f5ea',
    2: 'b5f4a5',
    3: '9ae6b4',
    4: 'ffe484',
    5: '93ddfd',
    6: '93ddfd',
    7: 'd9a9ff',
    8: 'd9a9ff',
    9: 'ff8383',
    10: 'ff8383',
}



export default function MorosidadMap() {
    let context = useContext(ConfigContext)
    let [cantidadFacturas, setCantidadFacturas] = useState(3)
    const [sts, setSts] = useState('C')
    
    const [clientes] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/utils/geojson/facturas/${cantidadFacturas}/${sts}`)
    let [activeLayer, setActiveLayer] = useState(null)
    let [kml, setKml] = useState(null)
    
    const BOUNDS_RG = {
        east: -67.59234,
        north: -53.708455,
        south: -53.83792,
        west: -67.80608
    }
    const BOUNDS_TOL = {
        east: -67.1571056,
        north: -54.4707852,
        south: -54.5577852,
        west: -67.2671056
    }
    const BOUNDS_USHU = {
        east: -68.1378226,
        north: -54.7568581,
        south: -54.8568581,
        west: -68.4378226
    }
    
    const initMap = (map, maps) => { 
        setGoogleMaps(maps) 
        setGMap(map); 
    }
    

    let [googleMaps, setGoogleMaps] = useState(null)

    useEffect(() => {
        googleMaps && setInfowindow(new googleMaps.InfoWindow());
    }, [googleMaps])

    let [gmap, setGMap] = useState(null)
    
  

    const applyLayers = (lay) => {
        if (lay === activeLayer) {
            setActiveLayer(null)
            kml.setMap(null)
        } else {
            setActiveLayer(lay)
            kml && kml.setMap(null)
            let url = `https://bridge.tvfuego.com.ar/kmz/${lay}.kmz`
            setKml(new googleMaps.KmlLayer({ url, gmap }))
        }
    }
    useEffect(() => { kml && kml.setMap(gmap) }, [kml, gmap])




    let [center, setCenter] = useState({ lat: CONSTS[context.empresa].lat, lng: CONSTS[context.empresa].lng })
    // let [zoom, setZoom] = useState(CONSTS[context.empresa].zoom)
    let [infowindow, setInfowindow] = useState()

    const [layers] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/mapLayers`)

    const mapOptions = {
        styles: MAPSTYLES[context.theme],
        backgroundColor: 'none',
        zoomControl: true,
        fullscreenControl: true,
        streetViewControl: true,
        restriction: {
            latLngBounds: context.empresa === 'tvf' ? BOUNDS_RG : context.empresa === 'uv' ? BOUNDS_USHU : BOUNDS_TOL,
            strictBounds: false
        },
    };
    useEffect(() => setCenter({ lat: CONSTS[context.empresa].lat, lng: CONSTS[context.empresa].lng }), [context.empresa])

    const handleOnGoogleApiLoaded = ({ map, maps }) => initMap(map, maps)

    useEffect(() => {

        if (gmap && clientes) {

            gmap.data.addGeoJson(clientes);

            gmap.data.addListener('click', function (event) {
                infowindow.setContent(`<p style="color:black;font-weight:bold;">Cliente ${event.feature.getProperty('nro')} debe ${event.feature.getProperty('imp')} facturas</p>`); 
                infowindow.setPosition(event.feature.getGeometry().get()); 
                infowindow.setOptions({ pixelOffset: new googleMaps.Size(0, -30) }); 
                infowindow.open(gmap);
            })

            gmap.data.setStyle((f) => {
                return { icon: {
                        url: `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${f.getProperty('imp')}|${FACTURAS_COLORES[f.getProperty('imp')]}|000000`
                    },
                    visible: sts.includes(f.getProperty('sts')),
                };
            });
        }

    }, [gmap, clientes, FACTURAS_COLORES])

    const handleSetCantidadFacturas = (e) => {
        gmap.data.forEach(function (feature) {
            gmap.data.remove(feature);
        });
        setCantidadFacturas(e.target.value)
    }
    const handleSetSts = (e) => {
        gmap.data.forEach(function (feature) {
            gmap.data.remove(feature);
        });
        setSts(e.target.value)
    }

    let [trafico, setTrafico] = useState(false)
    let [monitoreoLayer, setMonitoreoLayer] = useState(false)
    let [movilesLayer, setMovilesLayer] = useState(false)
    
    return (
        <main style={{ height: '100vh' }} className="overflow-hidden w-full relative h-full md:min-h-screen">

            <LayerSelector layers={layers} activeLayer={activeLayer} applyLayers={applyLayers}
                trafico={trafico} setTrafico={() => setTrafico(!trafico)}
                monitoreoLayer={monitoreoLayer} setMonitoreoLayer={() => setMonitoreoLayer(!monitoreoLayer)}
                movilesLayer={movilesLayer} setMovilesLayer={() => setMovilesLayer(!movilesLayer)} 
            />

            <div className=' w-46 mt-16 top-0 absolute   z-50 flex flex-col gap-3 ml-3'>
                <FloatingWindow title='Morosidad' icon='coins'>
                <div className='space-y-2 flex flex-col '>

                    <div className='flex space-x-2 items-center justify-between '>
                        <label>Facturas Impagas</label>
                        <input className='text-lg' min={1} max={20} type='number' onChange={handleSetCantidadFacturas} defaultValue={cantidadFacturas} />
                    </div>
                    <div className='flex space-x-2 items-center justify-between mt-2'>
                        <label>Estado</label>
                        <select onChange={handleSetSts} defaultValue={sts}>
                        {/* <option value=''>Todos</option> */}
                        <option value='C'>Conectado</option>
                        <option value='K'>Corte de señal</option>
                        <option value='X'>Desconectado</option>
                        <option value='E'>Basico</option>
                        {/* <option value='P'>Pendiente</option> */}
                        <option value='B'>Baja pendiente</option>
                        <option value='M'>Baja moroso</option>
                        {/* <option value='A'>A</option> */}
                        <option value='I'>Ingresado</option>
                    </select>
                    </div>
                    
                    { clientes && <h3 
                    className={`mt-2 text-center`} 
                    style={{ color : `#${FACTURAS_COLORES[cantidadFacturas]}`}}>
                    <strong>{clientes.features.filter(feature=> feature.properties.sts === sts).length}  </strong>
                    Clientes</h3>}
                </div>
                </FloatingWindow>
            </div>
            <GoogleMapReact
                options={mapOptions}
                center={center}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={handleOnGoogleApiLoaded}
                bootstrapURLKeys={{
                    key: context.gmapApiKey,
                    libraries: ['places', 'visualization'],
                }}
                defaultZoom={13} >

            </GoogleMapReact>
        </main>
    )
}
