import moment from 'moment'
const loginGuest = (username,password,setUser,setLogging,setLogged,notLogged,setEmpresa) => {
	let baseUrl = window.location.origin;
	let newPath = baseUrl + '/mapa/sion';

	const userData = { name: { 0: username }, userprincipalname: { 0: username }, dn: 'OU=guest', memberof: {0:"CN=G_CUO_GUEST"} }
	if (username === process.env.REACT_APP_LOGIN_EXTERNO_USER) {
		if (password === process.env.REACT_APP_LOGIN_EXTERNO_PASS) {
			setEmpresa('tvf')
			localStorage.setItem('empresa', 'tvf')
			localStorage.setItem('logged', true)
			localStorage.setItem('user', JSON.stringify(userData))
			localStorage.setItem('fend', moment().add(1, 'day'))
			setUser(userData)
			setLogging(false)
			setLogged(true)
			window.location.href = newPath;
		} else { notLogged('Contraseña Invalida') }
	} else { notLogged('Usuario Invalido') }
}


export default loginGuest