import React, { useState, useContext, useEffect  } from 'react';
import GoogleMapReact from 'google-map-react';
import CONSTS from '../consts/consts'
import MAPSTYLES from './../styles/mapstyles'
import ConfigContext from '../contexts/ConfigContext'
import LayerSelector from './Global/LayerSelector';
import CoordMarker from './Markers/CoordMarker'
import MovilMarker from './Markers/MovilMarker'
import MovilRedesMarker from './Markers/MovilRedesMarker'
import NodoMarker from './Markers/NodoMarker'
import useFetchCyberMapa from '../hooks/useFetchCyberMapa';
import useFetchMonitoreo from '../hooks/useFetchMonitoreo';
// import FloatingWindow from './Global/FloatingWindow';
import MainMapSearchBox from './MapTools/MainMapSearchBox';
import useFetch from '../hooks/useFetch';

const MapContainer = (props) => {
    let context = useContext(ConfigContext)
    // console.log('coordsExtra' , props.coordsExtra)

    let [movilesRedesLayer, setMovilesRedesLayer] = useState(false)
    let [movilesLayer, setMovilesLayer] = useState(false)
    let fetchedMoviles = useFetchCyberMapa(movilesLayer)

    let [monitoreoLayer, setMonitoreoLayer] = useState(false)
    let fetchedMonitoreo = useFetchMonitoreo(monitoreoLayer)

    const [layers] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/mapLayers`)

    let [activeLayer, setActiveLayer] = useState(null)
    let [statsWindow, setStatsWindow] = useState(false)
    let [googleMaps, setGoogleMaps] = useState(null)
    let [gmap, setGMap] = useState(null)
    let [kml, setKml] = useState(null)
    let [trafico, setTrafico] = useState(false)
    let [paths, setPaths] = useState(null)
    let [heatmap, setHeatmap] = useState(false)
    let [monitoreo, setMonitoreo] = useState(null)
    // let [zoom, setZoom] = useState(props.zoom || CONSTS[context.empresa].zoom)
    let [center, setCenter] = useState({ lat: CONSTS[context.empresa].lat, lng: CONSTS[context.empresa].lng })

    const BOUNDS_RG = {
        east: -67.59234,
        north: -53.708455,
        south: -53.83792,
        west: -67.80608
    }
    const BOUNDS_TOL = {
        east: -67.1571056,
        north: -54.4707852,
        south: -54.5577852,
        west: -67.2671056
    }
    const BOUNDS_USHU = {
        east: -68.1378226,
        north: -54.7568581,
        south: -54.8568581,
        west: -68.4378226
    }

    const initMap = (map, maps) => { setGMap(map); setGoogleMaps(maps) }

    function switchBounds(){
        if(props.mapIn){
            if(props.mapIn === 'tvf'){
                return BOUNDS_RG
            }else{
                if(props.mapIn === 'uv'){
                    return BOUNDS_USHU
                }else{
                    return BOUNDS_TOL
                }
            }
        }else{
            if(context.empresa === 'tvf'){
                return BOUNDS_RG
            }else{
                if(context.empresa === 'uv'){
                    return BOUNDS_USHU
                }else{
                    return BOUNDS_TOL
                }
            }
        }
    }

    const mapOptions = { 
        disableDefaultUI: true, 
        styles: MAPSTYLES[context.theme], 
        backgroundColor: 'none' ,
        zoomControl :true,
        fullscreenControl :true,
        streetViewControl:true,
        restriction: {
            latLngBounds: switchBounds(),
            strictBounds: false
        },
        };

    const heatMapOptions = { radius: 50, opacity: .8, gradient: [context.theme === 'dark' ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)', context.theme === 'dark' ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)', context.theme === 'dark' ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)', '#ECC94B', '#ECC94B', '#ED8936', '#ED8936', '#F56565'] }
    let pathBase = { geodesic: true, strokeColor: '#E53E3E', strokeOpacity: 0.9, strokeWeight: 5 };
    
    useEffect(() => { monitoreoLayer && setMonitoreo(fetchedMonitoreo) }, [monitoreoLayer])
    // useEffect(() => { movilesLayer && setMoviles(fetchedMoviles) }, [movilesLayer])
    useEffect(() => setCenter({ lat: CONSTS[context.empresa].lat, lng: CONSTS[context.empresa].lng }), [context.empresa])

    useEffect(() => { props.directions && googleMaps && setPaths(new googleMaps.Polyline( { ...pathBase,path: props.directions, strokeColor: props.directionsColor })); }, [props.directions, gmap, googleMaps])
    useEffect(()=>{ if(paths) { paths.setMap(gmap) }; },[paths])
    useEffect(() => { if (props.poly) { let paths = new googleMaps.Polyline({ ...pathBase, path: props.poly, }); paths.setMap(gmap); } }, [props.poly, gmap])
    // useEffect(() => { if (props.recenter != null) { setCenter({ lat: Number(props.recenter.lat), lng: Number(props.recenter.lng) }); setZoom(14); } }, [props.recenter])

    const applyLayers = (lay) => {
        if (lay === activeLayer) {
            setActiveLayer(null)
            kml.setMap(null)
        } else {
            setActiveLayer(lay)
            kml && kml.setMap(null)
            let url = `https://bridge.tvfuego.com.ar/kmz/${lay}.kmz`
            setKml(new googleMaps.KmlLayer({ url, gmap }))
        }
    }
    useEffect(() => { kml && kml.setMap(gmap) }, [kml, gmap])

    const convertToLatLng = (points) => {
        return points.filter(point => Number(point.lat) !== 0 || Number(point.lng) !== 0).map(point => {
            return { lng: Number(point.lat), lat: Number(point.lng) }
        })
    }
   
    const handleOnGoogleApiLoaded = ({ map, maps }) => initMap(map, maps)

    const arrayMovilRedes = ['11142','11143','11144','11145', '3847','3963','3967','3976','3972','3975','6763','3977','3965']

    return <main style={{ height: props.height ? props.height : '100vh' }} className="overflow-hidden w-full relative h-full md:min-h-screen">

        {props.supercanal 
            ? <LayerSelector supercanal={props.supercanal} applyLayers={applyLayers} />
            : <LayerSelector layers={layers} activeLayer={activeLayer} applyLayers={applyLayers}
                trafico={trafico} setTrafico={() => setTrafico(!trafico)}
                heatmap={heatmap} setHeatmap={() => setHeatmap(!heatmap)}
                monitoreoLayer={monitoreoLayer} setMonitoreoLayer={() => setMonitoreoLayer(!monitoreoLayer)}
                movilesLayer={movilesLayer} setMovilesLayer={() => setMovilesLayer(!movilesLayer)} 
                movilesRedesLayer={movilesRedesLayer} setMovilesRedesLayer={() => setMovilesRedesLayer(!movilesRedesLayer)} 
                statsWindow={statsWindow} setStatsWindow={()=>setStatsWindow(!statsWindow)}
                sizeOptionsMap={props.sizeOptionsMap}
            />
        }

        <div className=' w-56 mt-16 top-0 absolute   z-50 flex flex-col gap-3 ml-3'>
            { props.searchDirection && <MainMapSearchBox googleMaps={googleMaps} gmap={gmap}/> }
            { props.left }
        </div>

        <div className=' bottom-0 absolute z-50 flex flex-col items-start gap-3 ml-3 mb-3 '>
            { props.bottom }
            { statsWindow && props.stats } 
        </div>

        <div className=' w-56 mt-16 top-0 right-0 absolute  z-50 flex flex-col gap-3 mr-3'>
            { props.right }
        </div>
        
        
        <GoogleMapReact
            onChildClick={props.onChildClick || null}
            layerTypes={trafico ? ['TrafficLayer', 'TransitLayer'] : []}
            options={mapOptions}
            hoverDistance={20}
            heatmap={heatmap && props.points ? { positions: convertToLatLng(props.points), options: heatMapOptions } : { positions: [], options: heatMapOptions }}
            center={center}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={handleOnGoogleApiLoaded}
            bootstrapURLKeys={{ 
                key: context.gmapApiKey,
                libraries: ['places', 'visualization'],
            }}
            defaultZoom={13}
            >
            {props.children}
            {monitoreoLayer && monitoreo && Object.values(monitoreo).map((nodo, index) =>
                <NodoMarker
                    key={Object.keys(monitoreo)[index]}
                    lat={nodo.coordenadas.split(',')[0] || 0}
                    lng={nodo.coordenadas.split(',')[1] || 0}
                    nodo={nodo}
                    nombre={Object.keys(monitoreo)[index]} />)}

            {movilesLayer && fetchedMoviles && fetchedMoviles.filter((movil) => !arrayMovilRedes.includes(movil.id)).map((movil, index) => <MovilMarker lat={Number(movil.latitud)} lng={Number(movil.longitud)} key={'movil-' + index} movil={movil} />)}
            {movilesRedesLayer && fetchedMoviles && fetchedMoviles.filter((movil) => arrayMovilRedes.includes(movil.id)).map((movil, index) => <MovilRedesMarker lat={Number(movil.latitud)} lng={Number(movil.longitud)} key={'movil-' + index} movil={movil} />)}
            {props.coordsExtra && props.coordsExtra.map((coord, index) => 
                <CoordMarker   lat={coord.lng} lng={coord.lat} key={'coord-' + index}  />
            )}
        </GoogleMapReact>
    </main>
}
export default MapContainer
