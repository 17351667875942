import React, { useContext, useEffect } from "react";
import ConfigContext from "../../contexts/ConfigContext";
import SidebarLogo from "./SidebarLogo";
import SidebarThemeSelector from "./SidebarThemeSelector";

const SidebarSwitcher = () => {
  let { empresa, setEmpresa,roles } = useContext(ConfigContext);

  return (
    <div>
      <div className="flex flex-col md:flex-row mb-3 mt-3 md:mt-0 gap-2  w-full items-center justify-between">
        <SidebarLogo />
        <SidebarThemeSelector />
        {!roles.includes('guest') ? <select 
          value={empresa} 
          onChange={(e) => {
            setEmpresa(e.target.value)
            localStorage.setItem('empresa', e.target.value)
          }}
        >
          {["tvf", "uv", "tol"].map((em) => <option key={em} value={em}> {em}</option>)}
        </select> : null}
      </div>
    </div>
  );
};

export default SidebarSwitcher;
