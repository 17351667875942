import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetch = (url, headers) => {
    const [data, setData] = useState();
    const [error, setError] = useState();

    const fetchData = async () => {
        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': process.env.REACT_APP_BRIDGE_API_KEY,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    ...headers,
                },
            });
            setData(response.data);
        } catch (e) {
            console.log(`error: ${url} => `, e);
            setData();
            setError(e);
        }
    };

    useEffect(() => {
        if (url) {
            fetchData();
        }
    }, [url]);

    return [data, fetchData, error];
};

export default useFetch