import React, { useContext, useState } from 'react';

import Cargando from '../Global/Cargando'
import ConfigContext from '../../contexts/ConfigContext';
import Padding from '../Layout/Padding'
import useDelete from '../../hooks/configuracion/useDelete'
import useFetch from '../../hooks/useFetch'
import Icons from '../Global/IconList'
import {useHistory} from 'react-router-dom'

const LayersConfiguracion = (props) => {

    const history = useHistory()
    const context = useContext(ConfigContext)
    const [data, refreshData] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/prod/cuo/mapLayers`)
    
    const [ deleteItem ] = useDelete('mapLayers', '/configuracion/layers')

    const [openForm, setOpenForm] = useState(false)
    const [modeUpdate, setModeUpdate] = useState(false)
    const [updateId, setUpdateId] = useState()
    const [form, setForm] = useState()
    const [formKmz, setFormKmz] = useState()

    const handleNueva = () => {
        setModeUpdate(false)
        setOpenForm(!openForm)
        setForm({ ...form, mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0], _method:'post'})
    }
    
    const handleChange = e => {
        if(e.target.type === 'file'){
            setFormKmz({ ...formKmz, [e.target.name]: e.target.files[0] })
        }else{
            setForm({ ...form, [e.target.name]: e.target.value })
            if(e.target.name === 'file_name'){
                setFormKmz({ ...formKmz, name: e.target.value })
            }
        }
    }

    const handleStoreKmz = async (modeUpdate) => {
        let formData = new FormData();
        formKmz && Object.entries(formKmz).map(([key, value]) => {value && formData.append(key, value)})

        const url = `${process.env.REACT_APP_BRIDGE_API}/${context.empresa}/${context.env}/cuo/maps/kmz`
        let response = await fetch(url, { 
            method: 'POST', 
            body: formData,
            headers: { Authorization: process.env.REACT_APP_BRIDGE_API_KEY}
        })
        setFormKmz({})
        modeUpdate === true ? handleUpdate() : handleStore(response.status)
    }
 
    const handleStore = async (status) => {
        
        if (status === 200){
            const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/mapLayers`
            const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
            await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
            setForm({})
            setOpenForm(false)
            refreshData()
        }else{
            alert('Falta seleccionar archivo, o su archivo no es KMZ')
        }
    }
    const handleUpdate = async () => {
        const url = `${process.env.REACT_APP_CUO_API}/${context.empresa}/${context.env}/mapLayers/${updateId}`
        const headers = { Authorization: process.env.REACT_APP_CUO_API_KEY, 'Content-type': 'application/json' }
        await fetch(url, { method: 'POST', body: JSON.stringify(form), headers })
        setForm({})
        setOpenForm(false)
        refreshData()
    }

    const handleClickEditar = (layer) => {
        setOpenForm(!openForm) 
        setModeUpdate(true)
        setUpdateId(layer.id)
        setForm({...form, _method:'put', label:layer.label, icon:layer.icon, file_name:layer.file_name, mail: JSON.parse(localStorage.getItem('user')).userprincipalname[0]})
    }

    const handleClickDelete = (layer) => {
        deleteItem(refreshData, layer.label ,layer.id)
        setOpenForm(false)
    }
    
    const handleClickVolver = () => {history.goBack()}
    
    return (
        <Padding>
            <button onClick={handleClickVolver}><i className='fa fa-arrow-left'/> Volver</button>
            <div className='flex items-center justify-between'>
                <h1>Map Layers</h1>
                <button onClick={handleNueva}><i className={`fa fa-${openForm ? 'times' : 'plus'} mr-2`} /> {openForm ? 'Cancelar' : 'Nueva'}</button>
            </div>
            {data ?
                <table className="w-full">
                    <tbody>
                        {openForm &&
                            <tr>
                                <td className='py-3'><input onChange={handleChange} defaultValue={modeUpdate === true ? form.label : ''} name='label'  placeholder='Titulo' /></td>
                                <td className='py-3'>
                                    <select onChange={handleChange} defaultValue={modeUpdate === true ? form.icon : ''} name='icon'>
                                        <option value=''>Seleccione un icono</option>
                                        {Icons.map((icon,index) => <option key={index} value={icon}>{icon}</option>)}
                                    </select>
                                    
                                    {form && form.icon && <i className={`fa fa-${form.icon}`}/>}
                                </td>
                                <td className='py-3'> <input onChange={handleChange} defaultValue={modeUpdate === true ? form.file_name : ''} name='file_name' placeholder='File Name' /> </td>
                                <td className='py-3'><input type='file' name='file' onChange={handleChange} /></td>
                                <td className='text-right'> <button onClick={() => handleStoreKmz(modeUpdate)}><i className={`fa fa-${modeUpdate === true ? 'save' : 'paper-plane'} mr-2 `} /> {modeUpdate === true ? 'Actualizar' : 'Enviar'}</button> </td>
                            </tr>
                        }
                        
                        {data.map(item => <tr key={item.id}>
                            <td className='text-lg text-gray-600'>{item.label && item.label}</td>
                            <td className='text-xs text-gray-600'>{item.icon && <i className={`fa fa-${item.icon}`}/>}</td>
                            <td className='text-xs text-gray-600'>{item.file_name && item.file_name}</td>
                            <td className='text-right'>
                                <button onClick={() => handleClickEditar(item)} > <i className='fa fa-edit'/> Editar</button>
                                <button onClick={() => handleClickDelete(item)}> <i className='fa fa-trash'/> Eliminar</button>
                            </td>
                        </tr>)}
                    </tbody>
                </table> : <Cargando />}
        </Padding>
    )
}

export default LayersConfiguracion