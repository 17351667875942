import React, { useEffect, useState } from 'react';
import { Route, Switch } from "react-router-dom";
import ConfigContext from './contexts/ConfigContext';
import MainFrame from './components/Layout/MainFrame'
import SideBar from './components/Sidebar/Sidebar'
import useRole from './hooks/useRole'
import useFetch from './hooks/useFetch'

const DesktopApp = ({ empresa, setEmpresa, user}) => {
  const gmapApiKey = process.env.REACT_APP_GMAP_API
  const [env, setEnv] = useState('prod')
  const roles = useRole(user,empresa)
  const [theme, setTheme] = useState();
  
  const [rutas] = useFetch(`${process.env.REACT_APP_BRIDGE_API}/${process.env.REACT_APP_EMPRESA}/prod/cuo/routes`)

  useEffect(()=>{ 
    setTheme(localStorage.getItem('theme') || 'light') 
  },[])

  return (
    <ConfigContext.Provider value={{ empresa, setEmpresa , env, setEnv, theme, setTheme, gmapApiKey, user, roles}}>
      <div className={`theme-${theme} flex md:flex-row flex-col w-full`}>
        <SideBar options={rutas}  />
        <MainFrame>
          <Switch>
            {rutas && rutas
              .filter(ruta => {
                return ruta.role
                  .split(',')
                  .some(r => roles.indexOf(r) >= 0)
              })
              .map((ruta,index) => (
                <Route 
                  key={index} 
                  exact 
                  path={ruta.path} 
                  component={require('./'+ruta.component).default} 
                />
              ))
            }
            <Route 
              exact 
              path='*' 
              component={require('./Pages/OopsScreen').default} 
            />
          </Switch>
        </MainFrame>
      </div>
    </ConfigContext.Provider>
  )
}

export default DesktopApp;
